import Link, { LinkProps } from 'next/link';
import styles from './buttonStyles.module.scss';
import classNames from 'classnames';
import { CustomButtonProps, Url } from './types';

interface NextLinkProps extends LinkProps {
  className?: string;
  target?: string;
  children?: React.ReactNode;
}

type LinkButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  Omit<NextLinkProps, 'href'> &
  CustomButtonProps & {
    href?: Url;
  };

const cx = classNames.bind(styles);

const variantClassMap = {
  filled: 'v-filled',
  outlined: 'v-outlined',
  text: 'v-text',
};

const appearanceClassMap = {
  primary: 'a-primary',
  secondary: 'a-secondary',
  tertiary: 'a-tertiary',
};

const sizeClassMap = {
  xsmall: 's-xsmall',
  small: 's-small',
  medium: 's-medium',
  large: 's-large',
};

const radiusClassMap = {
  rounded: 'r-rounded',
  circular: 'r-circular',
  none: 'r-none',
};

// NexJS Link component
const NextLink: React.FC<NextLinkProps> = ({
  className,
  href,
  children,
  ...props
}) => {
  return (
    <Link href={href} className={cx(className)} {...props}>
      {children}
    </Link>
  );
};

// Native HTML Button component
const NativeButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  children,
  type = 'button',
  ...props
}) => {
  return (
    <button type={type} className={cx(className)} {...props}>
      {children}
    </button>
  );
};

// Button Icon
const Icon = ({
  icon,
  position = 'end',
}: {
  icon: React.ReactNode;
  position?: 'start' | 'end';
}) => <span className={styles[`${position}-icon`]}>{icon}</span>;

const LinkButton: React.FC<LinkButtonProps> = ({
  variant = 'filled',
  appearance = 'primary',
  size = 'medium',
  radius = 'rounded',
  className,
  children,
  href = '',
  headless = false,
  startIcon,
  endIcon,
  ...props
}) => {
  const cn = cx(
    !headless && styles['base-style'],
    styles[appearanceClassMap[appearance]],
    styles[variantClassMap[variant]],
    styles[sizeClassMap[size]],
    styles[radiusClassMap[radius]],
    className,
  );

  if (href && href !== '') {
    return (
      <NextLink className={cn} href={href} {...props}>
        {startIcon && <Icon icon={startIcon} position="start" />}
        {children}
        {endIcon && <Icon icon={endIcon} position="end" />}
      </NextLink>
    );
  }
  return (
    <NativeButton className={cn} {...props}>
      {startIcon && <Icon icon={startIcon} position="start" />}
      {children}
      {endIcon && <Icon icon={endIcon} position="end" />}
    </NativeButton>
  );
};

export default LinkButton;
