import {
  PrismicNextLink as BasePrismicNextLink,
  PrismicNextLinkProps,
} from '@prismicio/next';
import styles from './buttonStyles.module.scss';
import classNames from 'classnames';
import { CustomButtonProps } from './types';

const cx = classNames.bind(styles);

const variantClassMap = {
  filled: 'v-filled',
  outlined: 'v-outlined',
  text: 'v-text',
};

const appearanceClassMap = {
  primary: 'a-primary',
  secondary: 'a-secondary',
  tertiary: 'a-tertiary',
};

const sizeClassMap = {
  xsmall: 's-xsmall',
  small: 's-small',
  medium: 's-medium',
  large: 's-large',
};

const radiusClassMap = {
  rounded: 'r-rounded',
  circular: 'r-circular',
  none: 'r-none',
};

type CustomPrismicLinkProps = PrismicNextLinkProps & CustomButtonProps;

const Icon = ({
  icon,
  position = 'end',
}: {
  icon: React.ReactNode;
  position?: 'start' | 'end';
}) => <span className={styles[`${position}-icon`]}>{icon}</span>;

export default function PrismicNextLink({
  children,
  className,
  variant = 'filled',
  appearance = 'primary',
  size = 'medium',
  radius = 'rounded',
  headless = false,
  startIcon,
  endIcon,
  ...props
}: CustomPrismicLinkProps) {
  const cn = cx(
    !headless && styles['base-style'],
    styles[appearanceClassMap[appearance]],
    styles[variantClassMap[variant]],
    styles[sizeClassMap[size]],
    styles[radiusClassMap[radius]],
    className,
  );
  return (
    <BasePrismicNextLink className={cx(cn)} {...props}>
      {startIcon && <Icon icon={startIcon} position="start" />}
      {children}
      {endIcon && <Icon icon={endIcon} position="end" />}
    </BasePrismicNextLink>
  );
}
