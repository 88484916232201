import { Content } from '@prismicio/client';
import {
  SliceComponentProps,
  JSXMapSerializer,
  PrismicRichText,
} from '@prismicio/react';
import Container from '@/components/Unknown/Container';
import { PrismicNextLink } from '@/components/Internal/Button';
import { ArrowRightV2, QuoteRounded } from '@/components/Unknown/Icons';
import { PrismicNextImage } from '@prismicio/next';
// For carousel
import SwiperCore from 'swiper';
import { Pagination, Keyboard, Mousewheel, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Pagination, Keyboard, Mousewheel, Autoplay]);

/**
 * Props for `QuoteCarousel`.
 */
export type QuoteCarouselProps =
  SliceComponentProps<Content.QuoteCarouselSlice>;

/**
 * Component for "QuoteCarousel" Slices.
 */

const component: JSXMapSerializer = {
  heading2: ({ children }) => (
    <h2 className="text-pretty text-center font-bold ~lg:~text-arc-4xl/arc-6xl lg:text-left">
      {children}
    </h2>
  ),
  paragraph: ({ children }) => (
    <p className="mx-auto max-w-prose text-pretty text-center font-normal ~lg:~text-arc-base/arc-2xl lg:text-left">
      {children}
    </p>
  ),
};

const QuoteCarousel = ({ slice }: QuoteCarouselProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="bg-night-blue text-white ~/md:~py-16/20 md:~md/lg:~pt-20/[6.25rem] md:~md/lg:~pb-20/[4.5rem] lg:~lg:~pt-[6.25rem]/[11.25rem] lg:~lg:~pb-[4.5rem]/[5.625rem]"
    >
      <Container>
        <div className="grid grid-cols-1 justify-between gap-10 lg:grid-cols-[repeat(2,minmax(200px,500px))]">
          <article className="~px-4/11 ~pt-4/11 lg:relative lg:row-span-2">
            <QuoteRounded
              className={`mx-auto w-12 text-white lg:absolute lg:left-[-2.8%] lg:top-[-16%] lg:w-auto`}
            />
            <Swiper
              wrapperTag="ul"
              role="list"
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              spaceBetween={8}
              pagination={{
                clickable: true,
              }}
              loop
              className="h-auto lg:h-full"
              wrapperClass="~md/lg:~pb-11/20"
            >
              {slice.primary.quotes.map((item, index) => (
                <SwiperSlide
                  key={index}
                  tag="li"
                  role="listitem"
                  className="!w-full lg:w-auto"
                >
                  <article className="flex flex-col gap-9 text-white">
                    <PrismicRichText
                      field={item.quote}
                      components={{
                        paragraph: ({ children }) => (
                          <p className="mx-auto max-w-prose text-pretty text-center text-arc-xl font-normal italic lg:text-left lg:~lg:~text-arc-xl/arc-2xl">
                            {children}
                          </p>
                        ),
                      }}
                    />
                    <div className="flex flex-col items-center gap-4 lg:flex-row lg:gap-2">
                      <div className="flex items-center gap-2">
                        <PrismicNextImage
                          field={item.author_thumbnail}
                          fallbackAlt=""
                          className="rounded-full object-cover ~sm/md:~h-10/[3.4375rem] ~sm/md:~w-10/[3.4375rem]"
                        />
                        <span className="text-balance ~sm/md:~text-arc-sm/arc-lg">
                          {item.author_name}&#44; {item.author_position}
                        </span>
                      </div>
                      <PrismicNextImage
                        field={item.company_logo}
                        fallbackAlt=""
                        className="aspect-video w-24 object-contain lg:ml-auto lg:h-16 lg:w-auto"
                      />
                    </div>
                  </article>
                </SwiperSlide>
              ))}
            </Swiper>
          </article>
          <article
            className={`order-first o-6 ~pt-4/11 ~sm/lg:~px-4/2 ${slice.variation === 'default' ? 'lg:order-none' : ''}`}
          >
            <PrismicRichText
              field={slice.primary.heading}
              components={component}
            />
            {slice.primary.show_sub_heading && (
              <PrismicRichText
                field={slice.primary.sub_heading}
                components={component}
              />
            )}
          </article>
          {slice.primary.show_button && (
            <div
              className={`flex place-items-end justify-center ${slice.variation === 'default' ? 'lg:justify-end' : 'lg:justify-start'} `}
            >
              <PrismicNextLink
                field={slice.primary.button_link}
                variant="text"
                endIcon={<ArrowRightV2 />}
              >
                {slice.primary.button_label}
              </PrismicNextLink>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default QuoteCarousel;
