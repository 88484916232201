// Roadmap:
// [x] Add borderRadius prop
// [] Add fullWidth prop
// [x] Add basedColor prop -- maybe this is the same with appearance prop?
// [] Create util file for the css class mapping
// [x] Styling for disabled state

import PrismicNextLink from './PrismicNextLink';
import LinkButton from './LinkButton';

export { PrismicNextLink, LinkButton };
